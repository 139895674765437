<template>
    <!-- 消息提醒 -->
    <div>
        <message-list-scroll-wrapper/>
    </div>
</template>
<script>
import messageListScrollWrapper from "../components/scrollWrapper/messageList.vue";

export default {
    components: {
        messageListScrollWrapper
    },

}
</script>
<style lang="scss" scoped>

</style>
