<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2023-11-22 09:21:47
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-03-18 15:27:05
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\NotificationManager.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- 信息中心-通知管理 -->
  <div>
    <tabbed-page
        :tabList="tabList"
        @changeTabs="changeTabs"
    ></tabbed-page>
    <components :is="curComp"></components>
  </div>
</template>

<script>
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue';
import NotificationManagerScrollWrapper from "@/components/scrollWrapper/NotificationManager";
import { hasPermission } from "@/libs/utils.js";

export default {
  name: "NotificationManager",
  components: {
    TabbedPage,
    NotificationManagerScrollWrapper,
    LeaveAccessNoticeScrollWrapper: ()=> import(/* webpackChunkName:'LeaveAccessNoticeScrollWrapper' */"@/components/scrollWrapper/LeaveAccessNotice.vue"),
    AbnormalAttendanceNoticeScrollWrapper: ()=> import(/* webpackChunkName:'AbnormalAttendanceNoticeScrollWrapper' */"@/components/scrollWrapper/AbnormalAttendanceNotice.vue"),
  },
  data() {
    return {
      tabList: [
        {
          id: 0,
          title: '逾期通知',
          vHasPermi: ['interconnection:noticeConfig'],
          com: 'NotificationManagerScrollWrapper',
          sel: true
        },
        {
          id: 1,
          title: '出入通知',
          vHasPermi: ['leaveRecord:noticeConfig'],
          com: 'LeaveAccessNoticeScrollWrapper',
          sel: false
        },
        {
          id: 2,
          title: '考勤异常通知',
          vHasPermi: ['abnormalAttendance:noticeConfig'],
          com: 'AbnormalAttendanceNoticeScrollWrapper',
          sel: false
        },
      ],
      curComp: 'NotificationManagerScrollWrapper'
    }
  },
  created() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    init() {
      if (!hasPermission(['interconnection:noticeConfig'])) {
        this.changeTabs({ id: 1, com: 'LeaveAccessNoticeScrollWrapper' })
      } else {
        this.changeTabs({ id: 0, com: 'NotificationManagerScrollWrapper' })
      }
    },
    changeTabs (data) {
      this.curComp = data.com
      this.tabList.forEach((item, index) => {
        if (item.id === data.id) {
          this.$set(item, 'sel', true)
        } else {
          this.$set(item, 'sel', false)
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
