<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-01-09 09:30:32
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-01-09 10:10:25
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\NotificationList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 消息列表 -->
    <notification-list-scroll-wrapper></notification-list-scroll-wrapper>
</template>

<script>
import NotificationListScrollWrapper from "@/components/scrollWrapper/NotificationList";
export default {
    name: "NotificationList",
    components: {
        NotificationListScrollWrapper
    }
}
</script>

<style scoped>

</style>
